// - Formulario / Gravity Forms
$formulario-columnas-separacion: 15px;

// Separación entre campos
$mb: 14px;

// General
body .gform_wrapper.FORMULARIO-CUSTOM_wrapper {
  .top_label div.ginput_container {
    margin-top: 0;
  }

  ul li.gfield {
    margin-bottom: $mb;
    margin-top: 0;
  }
}

.gform_wrapper {
  &.FORMULARIO-CUSTOM_wrapper {
    margin-bottom: 0;
    margin-top: 0;

    form {
      display: flex;
      flex-wrap: wrap;
    }

    // Form title
    .gfield_html.gfield_no_follows_desc {
      margin-top: 0;
    }

    // Labels
    label.gfield_label,
    legend.gfield_label {
      @include labelStyles;
    }

    // Fields
    input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
    textarea,
    .gfield_select {
      @include fieldStyles;
    }

    .gfield_select {
      @include selectStyles('icono-flecha-select.svg');
    }

    input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
      height: $inputs-alto;
    }

    .gfield_checkbox,
    .gfield_radio {
      li {
        @include checkboxRadioStyles;
      }
    }

    .gfield_checkbox li {
      @include checkboxStyle('icono-checkbox-negro.svg');
    }

    .gfield_radio li {
      @include inputRadioStyles;
    }

    .gfield_required {
      color: inherit;
    }

    // Botón
    .gform_footer {
      margin-top: 20px;
      padding: 0;
      text-align: center;
    }

    .gform_footer input.button,
    .gform_footer input[type='submit'],
    .gform_page_footer input.button,
    .gform_page_footer input[type='submit'] {
      @include botonGeneral;
    }

    // Loader
    .gform_ajax_spinner {
      margin-top: 20px;
      width: 25px;
    }

    // Mensajes
    div.validation_error {
      border: 0;
      color: $input-borde-error;
      font-weight: inherit;
      margin-bottom: 0;
      order: 1;
      padding-bottom: 0;
      padding-top: 25px;
    }

    li.gfield.gfield_error.gfield_contains_required div.ginput_container,
    li.gfield.gfield_error.gfield_contains_required label.gfield_label {
      color: $negro;
      margin-top: 0;
    }

    li.gfield.gfield_error,
    li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
      background: none;
      border: 0;
      margin-bottom: $mb !important;
      padding: 0;
    }

    li.gfield_error input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
    li.gfield_error textarea {
      border-color: $input-borde-error;
    }

    // Error checkbox y radio
    .gfield_error {
      .gfield_checkbox li input[type='checkbox'] + label::before,
      .gfield_radio li input[type='radio'] label::before {
        border-color: $input-borde-error;
      }
    }

    .validation_message {
      display: none;
    }

    // Ajustes por navegadores
    &.gf_browser_chrome select,
    &.gf_browser_gecko select {
      padding: 11px 52px 9px 20px;
    }

    // Breakpoint de Gravity
    @media only screen and (min-width: 641px) {
      // Ajuste de columnas
      ul.gform_fields {
        margin-left: -$formulario-columnas-separacion!important;
        margin-right: -$formulario-columnas-separacion!important;

        li.gfield {
          padding-left: $formulario-columnas-separacion;
          padding-right: $formulario-columnas-separacion;
        }
      }

      .top_label li ul.gfield_checkbox,
      .top_label li ul.gfield_radio {
        width: 100%;
      }

      &.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
        max-width: 100%!important;
      }

      // Botón
      .gform_footer input.button,
      .gform_footer input[type='submit'],
      .gform_page_footer input.button,
      .gform_page_footer input[type='submit'] {
        width: 100%;
      }
    }
  }
}
