.bloque-community {
  padding: $separacion-bloques-sm 0 0;

  h3 {
    margin-bottom: 30px;
  }

  .boton-outline-rojo {
    margin-top: 20px;
  }
}

.slide-community {
  background-color: $fondo-1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-height: 180px;
  box-shadow: 0px 0px 20px 0px #0000001F;
  border-radius: 10px;
  margin: 15px;

  &::after {
    content: '';
    display: flex;
    padding-top: 61%;
  }
}

.bloque-animacion {
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

@include breakpoint('tablet') {
  .bloque-community {
    padding: $separacion-bloques-md 0 0;

    .boton-outline-rojo {
      margin-top: 50px;
    }
  }

  .bloque-animacion {
    background-position: center;
    background-size: cover;
  }
}

@include breakpoint('desktop-lg') {
  .bloque-community {
    padding: $separacion-bloques-lg 0 0;

    .boton-outline-rojo {
      margin-top: 80px;
    }
  }
}
