.bloque-testimoniales {
  padding: $separacion-bloques-sm 0;

  h3 {
    margin-bottom: 30px;
  }
}

.slide-testimonial {
  box-shadow: 0px 0px 20px 0px #0000001F;
  background-color: $blanco;
  padding: 32px;
  border-radius: 10px;
  margin: 15px;

  &_resena {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &_autor {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      max-width: 65px;
      margin-right: 16px;
    }
  }

  &_datos {
    span:first-child {
      @include dmSans_bold;

      font-size: 18px;
      line-height: 25px;
      display: block;
      color: $dark-blue;
    }
  }

  .stars-1 {
    background-image: url('img/1-stars.svg');
  }

  .stars-2 {
    background-image: url('img/2-stars.svg');
  }

  .stars-3 {
    background-image: url('img/3-stars.svg');
  }

  .stars-4 {
    background-image: url('img/4-stars.svg');
  }

  .stars-5 {
    background-image: url('img/5-stars.svg');
  }

  .stars-1,
  .stars-2,
  .stars-3,
  .stars-4,
  .stars-5 {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 110px;
    height: 24px;
    display: block;
    margin-top: 5px;
    margin-left: -2px;
  }
}

@include breakpoint('tablet') {
  .bloque-testimoniales {
    padding: $separacion-bloques-md 0;
  }
}

@include breakpoint('desktop-lg') {
  .bloque-testimoniales {
    padding: $separacion-bloques-lg 0;
  }
}
