.bloque-img {
  background-color: $red;
  color: $blanco;
  padding: $separacion-bloques-sm 0;
  position: relative;

  &::after {
    bottom: 22px;
    left: 0;
  }

  &::after,
  &::before {
    content: '';
    display: block;
    width: 50px;
    height: 100px;
    background-image: url('img/dot-grid.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    opacity: 0.5;
  }

  &::before {
    top: 22px;
    right: 0;
  }

  h3 {
    color: $blanco;
    margin-bottom: 15px;
  }

  .bloque-descripcion {
    margin-bottom: 30px;
  }

  img {
    border-radius: 7px;
  }

  h3,
  .bloque-descripcion,
  img {
    position: relative;
    z-index: 1;
  }
}

// .bloque-maps {
//   background-color: $fondo-1;
//   overflow: hidden;
//   position: relative;

//   &::before {
//     content: '';
//     display: block;
//     padding-bottom: 100%;
//   }

//   iframe {
//     height: 100%;
//     left: 0;
//     position: absolute;
//     top: 0;
//     width: 100%;
//   }
// }


@include breakpoint('tablet') {
  .bloque-img {
    padding: $separacion-bloques-md 0;
  
    &::after {
      width: 100px;
      height: 150px;
      bottom: 14px;
    }
  
    &::before {
      width: 100px;
      height: 150px;
      top: 140px;
      right: -10px;
    }
  
    h3 {
      margin-bottom: 15px;
    }
  
    .bloque-descripcion {
      margin-bottom: 30px;
    }
  }
}

@include breakpoint('desktop-lg') {
  .bloque-img {
    padding: $separacion-bloques-lg 0;
  
    &::after {
      width: 100px;
      height: 200px;
      bottom: 15px;
      background-size: contain;
    }
  
    &::before {
      width: 100px;
      height: 200px;
      top: 165px;
      right: 0;
      background-size: contain;
    }
  
    h3 {
      margin-bottom: 15px;
    }
  
    .bloque-descripcion {
      margin-bottom: 50px;
    }
  }
}

@include breakpoint('desktop-xl') {
  .bloque-img {
  
    &::after {
      bottom: 25px;
      width: 133px;
    }
  
    &::before {
      width: 133px;
      top: 157px;
    }
  
    h3 {
      margin-bottom: 15px;
    }
  
    .bloque-descripcion {
      margin-bottom: 60px;
    }
  }
}
