.bloque-texto {
  padding-top: $separacion-bloques-sm * 2;
  padding-bottom: $separacion-bloques-sm * 2;

  &_title {
    margin-bottom: 16px;
  }
}

.bloque-listas {
  padding-top: $separacion-bloques-sm;
  padding-bottom: $separacion-bloques-sm;
  background-color: $dark-blue;
  background-image: url('img/test-item-column-2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  color: $blanco;

  &::before {
    position: absolute;
    content: '';
    display: block;
    background-color: #013B74D9;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
  
  h3 {
    color: $blanco;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;
  }
}

@include breakpoint ('tablet') {
  .bloque-texto {
    padding-top: $separacion-bloques-md * 2;
    padding-bottom: $separacion-bloques-md * 2;
  }

  .bloque-listas {
    padding-top: $separacion-bloques-md;
    padding-bottom: $separacion-bloques-md;
  }
}

@include breakpoint ('desktop-lg') {
  .bloque-texto {
    padding-top: $separacion-bloques-lg * 2;
    padding-bottom: $separacion-bloques-lg * 2;
  }

  .bloque-listas {
    padding-top: $separacion-bloques-lg + 20;
    padding-bottom: $separacion-bloques-lg + 20;
  }
}
