.lg-toolbar {
  background-color: $negro;
  position: absolute;

  .lg-close {
    background-color: rgba(#626262, 0.3);
    height: 40px;
    transition: 0.5s;
    width: 40px;
  
    &::after {
      background-image: url('img/icon-close-blanco.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

.lg-actions {
  .lg-prev,
  .lg-next {
    background-color: $blanco;
    display: block;
    height: 40px;
    transition: 0.5s;
    width: 40px;
  }

  .lg-prev {
    &::after {
      background-image: url('img/arrow-red-left.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .lg-next {
    &::before {
      background-image: url('img/arrow-red-rigth.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
      content: '';
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

.lg-outer .lg-image {
  border-radius: 10px;
}
