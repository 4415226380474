.bloque-membership {
  background-color: $fondo-3;
  padding-top: 156px;
  padding-bottom: $separacion-bloques-sm;

  &_logo-wrap {
    text-align: center;
    margin-bottom: 50px;

    img {
      max-width: 300px;
    }
    
    h1 {
      @include encabezadoH3;

      margin-bottom: 16px;
    }

    .bloque-membership_logo-desc {
      @include dmSans_bold;

      font-size: 20px;
      line-height: 28px;
    }
  }

  &_card {
    background-color: $blanco;
    box-shadow: 0px 0px 20px 0px #0000001F;
    border-radius: 10px;
    padding: 20px;
    text-align: center;

    h3 {
      margin-bottom: 20px;
    }

    &-body {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 10px;
    }

    h5 {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

.bloque-acordeon_img {
  background-color: $fondo-3;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;

  &::before {
    display: block;
    content: '';
    padding-top: 100%;
  }
}

.bloque-form {
  padding-top: $separacion-bloques-sm;
  padding-bottom: $separacion-bloques-sm;
  background-color: $fondo-2;

  .contact-hero_title {
    @include encabezadoH1;
  }

  .contact-hero {
    padding-bottom: 0;
  }
}

@include breakpoint ('tablet') {
  .bloque-membership {
    padding-top: 166px;
    padding-bottom: $separacion-bloques-md;
  
    &_card {
      padding: 40px;
    }
  }

  .bloque-acordeon_img {
    &::before {
      padding-top: 90%;
    }
  }

  .bloque-form {
    padding-top: $separacion-bloques-md;
    padding-bottom: $separacion-bloques-md;
  }
}

@include breakpoint ('desktop-lg') {
  .bloque-membership {
    padding-top: 226px;
    padding-bottom: $separacion-bloques-lg;

    &_logo-wrap {
      img {
        max-width: 435px;
      }
    }
  
    &_card {
      padding: 60px;
    }
  }

  .bloque-acordeon_img {
    &::before {
      padding-top: 75%;
    }
  }

  .bloque-form {
    padding-top: $separacion-bloques-lg;
    padding-bottom: $separacion-bloques-lg;
  }
}
