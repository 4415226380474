.contact-wrapper {
  border-bottom: 1px solid #DDDDDD;
  padding-top: 180px;
  background: linear-gradient($fondo-2 29%, $blanco 0%);
}

.contact-hero {
  padding-bottom: $separacion-bloques-sm;

  &_title {
    margin-bottom: 16px;
    text-align: center;
  }

  &_cta {
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    position: relative;

    &::before {
      content: '';
      display: block;
      background-color: $dark-blue;
      height: 1px;
      width: 80px;
      position: absolute;
      bottom: 0;
      right: 50%;
      transform: translateX(50%);
    }

    a {
      color: $blue;
    }

    a:hover {
      opacity: 0.5;
    }
  }

  &_descr {
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 40px;
  }
}

.form-card {
  background-color: $blanco;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000001F;
  padding: 20px;
}

.bloque-how {
  padding-top: $separacion-bloques-sm;
  padding-bottom: $separacion-bloques-sm;
  border-top: 1px solid #DDDDDD;

  h3 {
    margin-bottom: 30px;
  }
}

.item-circle {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  h4 {
    font-size: 24px;
    line-height: 34px;
  }

  &_circle {
    @include workSans_bold;

    border: 10px solid #CCD7E4;
    background-color: $blue;
    border-radius: 100%;
    color: $blanco;
    font-size: 48px;
    line-height: 56px;
    width: 130px;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 35px;

    img {
      width: 50px;
    }
  }

  .boton-principal {
    margin-top: 12px;
  }
}

@include breakpoint ('tablet') {
  .contact-wrapper {
    padding-top: 190px;
    background: linear-gradient($fondo-2 40%, $blanco 0%);
  }

  .contact-hero {
    padding-bottom: $separacion-bloques-md;
  
    &_cta {
      margin-bottom: 40px;
      padding-bottom: 40px;
    }
  
    &_descr {
      margin-bottom: 60px;
    }
  }

  .form-card {
    padding: 40px;
  }

  .bloque-how {
    padding-top: $separacion-bloques-md;
    padding-bottom: $separacion-bloques-md;

    h3 {
      margin-bottom: 40px;
    }
  }

  .items-circle-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .item-circle {
    width: 50%;

    &_circle {
      margin: 0 0 35px;
    }
  }
}

@include breakpoint ('desktop-lg') {
  .contact-wrapper {
    padding-top: 245px;
    background: linear-gradient($fondo-2 47%, $blanco 0%);
  }

  .contact-hero {
    padding-bottom: $separacion-bloques-lg;
  }

  .form-card {
    padding: 60px;
  }
  
  .bloque-how {
    padding-top: $separacion-bloques-lg;
    padding-bottom: $separacion-bloques-lg;

    h3 {
      margin-bottom: 50px;
    }
  }

  .items-circle-wrap {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 80%;
      background-color: #CCD7E4;
      height: 5px;
      position: absolute;
      top: 65px;
    }
  }

  .item-circle {
    width: 25%;
    padding-right: 15px; 

    &_circle {
      position: relative;
    }
  }
}
