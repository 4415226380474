.bloque-cta {
  background-color: $red;
  background-image: url('img/bg-trio.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $blanco;
  padding: $separacion-bloques-sm 0;
  position: relative;
  z-index: 1;
  text-align: center;

  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 150px;
    background-image: url('img/dot-grid-dark.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.5;
  }

  &::before {
    content: '';
    display: block;
    width: 100px;
    height: 150px;
    background-image: url('img/dot-grid-dark.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0;
    opacity: 0.5;
  }

  h2 {
    @include encabezadoH3;

    color: $blanco;
    margin-bottom: 15px;
  }

  &-description {
    @include dmSans_regular;

    font-size: 22px;
    line-height: 27px;
  }

  &.bloque-cta-var {
    background-color: $blue;

    
  }
}

@include breakpoint('tablet') {
  .bloque-cta {
    padding: $separacion-bloques-md 0;
  }
}

@include breakpoint('desktop-lg') {
  .bloque-cta {
    padding: $separacion-bloques-lg+20px 0;

    h2 {
      @include encabezadoH2;
    }
  }
}
