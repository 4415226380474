header {
  position: fixed;
  width: 100%;
  z-index: 10;
}

.menu-scrolled {
  background-color: rgba($blanco, 0.15);
  width: 100%;
}

.menu__logo {
  max-height: 50px;
  width: auto;
}

.menu-principal-wrapper {
  border-top: 1px solid rgba($blanco, 0.3);
  padding: 10px 15px 10px 15px;
  width: 100%;
  z-index: 100;

  .navbar {
    padding: 0;
  }

  .navbar-brand {
    margin-right: 10px;
  }
}

.header-border {
  border-bottom: 1px solid #DDDDDD;
}

.icon-photos,
.icon-rebates,
.icon-financing,
.icon-offers,
.icon-careers {
  a {
    background-position: left center;
    background-size: 16px;
    background-repeat: no-repeat;
    padding-left: 26px;
  }
}

.icon-photos a {
  background-image: url('img/icon-photos.svg');
}

.icon-rebates a {
  background-image: url('img/icon-rebates.svg');
}

.icon-financing a {
  background-image: url('img/icon-financing.svg');
}
.icon-offers a {
  background-image: url('img/icon-offers.svg');
}
.icon-careers a {
  background-image: url('img/icon-careers.svg');
}

.menu-scrolled.scrolled,
.header-color {
  transition: background-color 200ms linear;
  background-color: rgba($blanco, 0.8);

  .menu-principal-wrapper {
    border-top: 1px solid rgba($dark-blue-2, 0.3);
  }

  .header-phone {
    color: $dark-blue-2;
    // border-right: 1px solid rgba($dark-blue-2, 0.3);
  
    &:hover {
      color: rgba($dark-blue-2, 0.9);
    }
  }

  .menu-item-mobile {
    img {
      filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(289deg) brightness(110%) contrast(110%);
    }
  }

  .top-menu.menu-desktop {
    a {
      color: $dark-blue-2;
    }

    img {
      filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(289deg) brightness(110%) contrast(110%);
    }

    .icon-photos a {
      background-image: url('img/icon-photos-dark.svg');
    }
    
    .icon-rebates a {
      background-image: url('img/icon-rebates-dark.svg');
    }
    
    .icon-financing a {
      background-image: url('img/icon-financing-dark.svg');
    }
    .icon-offers a {
      background-image: url('img/icon-offers-dark.svg');
    }
    .icon-careers a {
      background-image: url('img/icon-careers-dark.svg');
    }
  }

  .mburger {
    @include botonHamburguesa($dark-blue-2, 38px);
  
    &:hover {
      color: rgba($negro, 0.5);
    }
  }
}

.header-phone {
  @include workSans_bold;

  color: $blanco;
  display: block;
  font-size: 21px;
  line-height: 25px;
  padding: 20px 15px 20px 15px;
  text-align: center;

  &:hover {
    color: rgba($blanco, 0.8);
    text-decoration: underline;
  }
}

.top-menu {
  a {
    display: flex;
  }

  img {
    margin-right: 6px;
  }

  &.menu-desktop {
    display: none;
  }
}

.menu-item.menu-mobile {
  a {
    display: flex;
  }

  img {
    margin-right: 6px;
  }
}

@include breakpoint ('tablet-sm') {
  .menu-principal-wrapper {
    padding: 10px 15px;

    .navbar {
      max-width: 540px;
      margin: 0 auto;
    }
  }

  .top-menu-container {
    margin: 0 auto;
    max-width: 540px;
    width: 100%;
  }
}

@include breakpoint ('tablet') {
  .menu-principal-wrapper {
    padding: 10px 0;
    
    .navbar {
      max-width: 720px;
    }
  }

  .top-menu-container {
    max-width: 720px;
  }
}

@include breakpoint ('desktop-lg') {
  .menu-principal-wrapper {
    .navbar {
      max-width: 960px;
      display: flex;
      justify-content: space-between;
    }
  }

  .menu-scrolled.scrolled,
  .header-color {
    .menu-item .dropdown-toggle {
      color: $dark-blue-2;
    }

    .header-phone {
      border-right: 1px solid rgba($dark-blue-2, 0.3);
    }

    .top-menu_container .menu-item-mobile {
      color: $dark-blue-2;
    }
  }

  .top-menu_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    padding-left: 20px;

    .menu-item-mobile {
      @include workSans_bold;
  
      font-size: 16px;
      line-height: 19px;
      color: $blanco;
      display: flex;
      align-items: center;
  
      img {
        height: 28px;
        margin-bottom: 0;
        margin-right: 6px;
        width: 28px;
      }
    }
  }

  .menu__logo {
    max-height: 100px;
    width: auto;
  }

  .top-menu-container {
    display: flex;
    max-width: 960px;
  }

  header {
    display: flex;
    flex-wrap: wrap;
  }

  .top-menu.menu-desktop {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 0;
    gap: 32px;

    li {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    a {
      @include workSans_regular;

      align-items: center;
      color: $blanco;
      flex-direction: column;
      font-size: 16px;
      justify-content: center;
      line-height: 19px;
    }

    img {
      height: 16px;
      margin-bottom: 6px;
      margin-right: 0;
      width: 16px;
    }
    
  }

  .header-phone {
    border-right: 1px solid rgba($blanco, 0.3);
    padding: 20px 10px 20px 10px;
    width: 20%;
  }

  .menu-principal-wrapper {
    width: 100%;
  }

  .menu-principal {
    flex-grow: initial;
  }

  .menu-principal__items {
    .menu-item {
      padding: 10px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .menu-item.menu-item__request {
      padding: 10px 0 10px 2px;
    }

    .dropdown:hover > .dropdown-menu {
      display: block;
      margin-top: 0;
      top: 75px;
    }

    .dropdown:hover > .menu-item_locations.dropdown-menu {
      display: flex;
    }

    .dropdown:hover .dropdown-toggle::after {
      transition: 0.5s;
      transform: rotate(180deg);
    }

    .dropdown-menu {
      background-color: $fondo-3;
      border: none;
      padding: 0;
      overflow: hidden;
      width: 356px;
      padding-top: 16px;
      box-shadow: 0px 0px 20px 0px #0000001F;
    }

    .dropdown-item {
      padding: 10px 32px;
      color: $dark-blue;

      &:hover {
        background-color: rgba($dark-blue-2, 0.3);
      }
    }
  }

  .menu-item .dropdown-toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $blanco;

    &::after {
      margin-top: 20px;
    }
  }

  .menu-item.menu-item__request {
    a {
      @include botonPrincipal;
      @include botonGeneral;

      border: 2px solid $red;
    }
  }

  .menu-item.menu-mobile {
    display: none;
  }

  .menu-item_locations.dropdown-menu{
    // display: flex;
    flex-wrap: wrap;
    width: 788px;
    padding: 22px;
    left: 0%;
    transform: translateX(-86%);
    box-shadow: 0px 0px 20px 0px #0000001F;

    li {
      width: 33%;
    }

    .dropdown-item {
      white-space: unset;

      &:hover {
        background-color: unset;
      }

      span {
        display: block;
        max-width: 220px;
      }
    }
  }

  .menu-card_wrap {
    display: block !important;
    padding: 16px 32px 32px;

    .menu-card {
      background-color: $blanco;
      border-radius: 10px;
      box-shadow: 0px 0px 15px 0px #0000000D;
      padding: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .menu-card_img {
      width: 25%;
      text-align: center;
      margin-right: 10px;
    }

    img {
      width: 60px;
      max-height: 60px;
    }

    .menu-card_info {
      width: 75%;
    }

    h6 {
      color: $blue;
    }

    span,
    a {
      display: block
    }

    a {
      @include workSans_bold;

      color: $red;
      margin-top: 10px;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

@include breakpoint ('desktop-xl') {
  .menu-principal-wrapper {
    .navbar {
      max-width: 1140px;
    }
  }

  .top-menu-container {
    max-width: 1140px;
  }

  .top-menu.menu-desktop {

    a {
      flex-direction: row;
    }

    img {
      margin-bottom: 0px;
      margin-right: 6px;
    }
  }

  .menu-principal__items {
    .menu-item {
      padding: 10px 14px;
    }

    .menu-item.menu-item__request {
      padding: 10px 0 10px 14px;
    }
  }
}

@include breakpoint ('desktop-xxl') {
  .menu-principal-wrapper {
    .navbar {
      max-width: 1320px;
    }
  }

  .top-menu-container {
    max-width: 1320px;
  }

  .menu-principal__items {
    .menu-item {
      padding: 10px 20px;
    }

    .menu-item.menu-item__request {
      padding: 10px 0 10px 20px;
    }
  }
}
