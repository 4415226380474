.contenido-detalle {
  padding: 175px 0 $separacion-bloques-sm;

  .detalle-titulo {
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 30px;
    text-align: center;
  }

  .detalle-destacada {
    margin-bottom: 30px;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #DDDDDD;

    &::before {
      content: '';
      display: block;
      padding-top: 80%;
    }
  }
}

@include breakpoint ('tablet') {
  .contenido-detalle {
    padding: 185px 0 $separacion-bloques-md;

    .detalle-destacada {
      &::before {
        padding-top: 60%;
      }
    }
  }
}

@include breakpoint ('desktop-lg') {
  .contenido-detalle {
    padding: 245px 0 $separacion-bloques-lg;
  }
}
