.contenedor-404 {
  padding: 234px 0 120px;
  // border-bottom: 1px solid #DDDDDD;
  text-align: center;

  &_img {
    width: 100px;
    height: 100px;
    margin-bottom: 32px;
  }

  h1 {
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 16px;
  }

  &_description {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@include breakpoint ('tablet') {
  .contenedor-404 {
    padding: 287px 0 120px;
  }
}
