.bloque-slide-team {
  padding: $separacion-bloques-sm 0;
  background-color: $fondo-1;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 70px;
    height: 100px;
    background-image: url('img/dot-grid.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 22px;
    left: 0;
    opacity: 0.5;
  }

  &::before {
    content: '';
    display: block;
    width: 70px;
    height: 100px;
    background-image: url('img/dot-grid.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 22px;
    right: 0;
    opacity: 0.5;
  }

  h3 {
    text-align: center;
    margin-bottom: 40px;
  }
}

.slide-team {
  margin-left: 10px;
  margin-right: 12px;
}

.slide-team_img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin-bottom: 20px;

  &::before {
    content: '';
    display: block;
    padding-top: 90%;
  }
}

.slide-team_link {
  display: block;
  margin-bottom: 30px;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 23px;
    height: 15px;
    background-image: url('img/arrow-red-rigth.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 15px;
    right: 0;
  }

  &:hover {
    opacity: 0.5;
  }

  .slide-team_wrap {
    box-shadow: 0px 0px 20px 0px #0000001F;
    background-color: $blanco;
    border-radius: 6px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  img {
    width: 35px;
    height: 35px;
  }

  span {
    @include workSans_semibold;

    font-size: 22px;
    line-height: 33px;
    color: $dark-blue;
    margin-bottom: 8px;
    display: block;
  }

  p {
    @include dmSans_regular;

    color: $dark-blue-2;
  }
}

@include breakpoint ('tablet') {
  .bloque-slide-team {
    padding: $separacion-bloques-md 0;
  }

  .slide-team.slick-slide {
    display: flex;
    flex-direction: row;
    gap: 20px;
    // margin-right: 4px;
  }

  .slide-team_img {
    margin-bottom: 20px;
    width: 50%;
  
    &::before {
      padding-top: 70%;
    }
  }

  .slide-team_services {
    width: 50%;

    &.slide-service-child {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-between;

      .slide-team_link {
        width: 48%;

        .slide-team_wrap {
          width: 70px;
          height: 70px;
        }
      
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

@include breakpoint ('desktop-lg') {
  .bloque-slide-team {
    padding: $separacion-bloques-lg 0;

    &::before,
    &::after {
      width: 130px;
      height: 150px;
    }
  }

  .slide-team.slick-slide {
    gap: 30px;
  }

  .slide-team_img {
    margin-bottom: 20px;
    width: 49%;
  
    &::before {
      padding-top: 70%;
    }
  }

  .slide-team_services {
    width: 49%;
  }

  .slide-team_link {
    display: flex;
    padding-right: 50px;
    align-items: center;

    &::before {
      top: 50%;
      transform: translateY(-50%);
    }
  
    .slide-team_wrap {
      margin-bottom: 0;
      margin-right: 24px;
      flex-shrink: 0;
    }
  }
}
