.hero-home {
  background-color: $dark-blue;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 200px;
  position: relative;

  &::before {
    content: '';
    display: block;
    background-color: #0D4985B2;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  h1 {
    @include workSans_extrabold;

    color: $blanco;
    font-size: 54px;
    line-height: 60px;
  }
}

.hero-contenido {
  position: relative;
  z-index: 1;
}

.hero-description {
  color: #EDEDED;
  font-size: 22px;
  line-height: 29px;
  margin-bottom: 24px;

  p {
    margin-bottom: 0;
  }
}

.hero-botones {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.hero-img-tech {
  max-width: 250px;
  margin-top: 40px;
}

.hero-simple {
  background-color: $dark-blue;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 230px 0 70px;
  position: relative;

  &::before {
    content: '';
    display: block;
    background-color: #0D4985B2;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  h1 {
    color: $blanco;
    position: relative;
    z-index: 1;
    text-align: center;
  }
}

.hero-service {
  background-color: $fondo-2;
  padding: 200px 0 0;

  h1 {
    margin-bottom: 20px;
  }

  a + a {
    margin-top: 24px;
  }

  .boton-principal,
  .boton-outline-rojo {
    display: block;
  }

  &_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 350px;
    margin-top: 40px;

    &::after {
      content: '';
      display: block;
      padding-top: 60%;
    }
  }

  &.hero-mitad {
    padding: 230px 0 350px;

    .hero-service_img {
      height: 300px;
    }
  }

  &.hero-mitad.hero-careers {
    padding-bottom: 0px;
  }
}

.hero-service_promo {
  margin-top: -70px;
}

.hero-discount {
  background-color: $blue;
  box-shadow: 0px 0px 20px 0px #0000001F;
  border-radius: 50px 10px;
  padding: 30px;
  position: relative;

  &_heading {
    margin-bottom: 40px;

    h2 {
      @include encabezadoH3;

      color: $blanco;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 85px;
    height: 150px;
    background-image: url('img/dot-grid.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.5;
  }

  &::before {
    content: '';
    display: block;
    width: 140px;
    height: 73px;
    background-image: url('img/dot-grid.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150px 150px;
    position: absolute;
    top: 0;
    right: 50px;
    opacity: 0.5;
    z-index: 0;
  }

  &_img {
    position: relative;
    z-index: 1;
  }

  .link-arrow {
    @include workSans_bold;

    color: $blanco;
    display: inline-block;
    position: relative;
    padding-right: 27px;
    font-size: 19px;
    line-height: 22px;

    &::before {
      content: '';
      display: block;
      width: 17px;
      height: 19px;
      background-image: url('img/arrow-white-rigth.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 17px 19px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

@include breakpoint('tablet') {
  .hero-home {
    padding: 200px 0 80px;
  }

  .hero-img-tech {
    max-width: 250px;
    margin-top: 0;
    margin-bottom: -80px;
  }

  .hero-service {
    padding: 230px 0 250px;
    position: relative;
  
    .boton-principal + .boton-principal,
    .boton-outline-rojo + .boton-outline-rojo {
      margin-left: 24px;
    }

    a + a {
      margin-top: 0;
    }

    .boton-principal {
      margin-bottom: 24px;
    }
  
    .boton-principal,
    .boton-outline-rojo {
      display: inline-block;
      min-width: 182px;
    }
  
    &_img {
      position: absolute;
      right: 0;
      width: 40%;
      height: 100%;
      bottom: 0;
    }

    &.hero-mitad {
      padding: 230px 0 100px;
  
      .hero-service_img {
        height: 100%;
        bottom: 0;
        width: 45%;
      }
    }

    &.hero-mitad.hero-careers {
      padding-bottom: 100px;
    }
  }

  .hero-service_promo {
    margin-top: -180px;
  }

  .hero-discount {
    padding: 0px;
    display: flex;
    align-items: stretch;
  
    &_heading {
      margin-bottom: 0px;
      padding: 30px;
  
      h2 {
        @include encabezadoH2;
      }
    }

    &_img {
      background-image: url('img/bg-ellipse.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    &::before {
      width: 140px;
      height: 73px;
      background-size: 150px 150px;
      right: 250px;
    }
  }
}

@include breakpoint('desktop-lg') {
  .hero-home {
    padding: 250px 0 130px;

    h1 {
      font-size: 74px;
      line-height: 89px;
    }
  }

  .hero-img-tech {
    max-width: 350px;
    margin-top: 0;
    margin-bottom: -130px;
  }

  .hero-simple {
    padding: 300px 0 120px;
  }

  .hero-service {
    padding: 280px 0 200px;
  
    &_img {
      width: 40%;
      height: 100%;
      bottom: 0;
    }

    &.hero-mitad {
      padding: 290px 0 100px;
  
      .hero-service_img {
        width: 49%;
      }

      .hero-service_inner {
        padding-right: 30px;
      }
    }
  }

  .hero-service_promo {
    margin-top: -130px;
  }

  .hero-discount {
    padding: 0px;
  
    &_heading {
      margin-bottom: 0px;
      padding: 50px 15px 50px 100px;
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
  
      h2 {
        @include encabezadoH2;
      }
    }

    &_img {
      background-image: url('img/bg-ellipse.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left;
      padding: 0px 100px 0px 55px;
      width: 40%;

      img {
        max-width: 370px;
        width: 100%;
      }
    }
  
    &::before {
      width: 140px;
      height: 73px;
      background-size: 150px 150px;
      right: 34%;
    }
  }
}

@include breakpoint ('desktop-xl') {
  .hero-service {
    &.hero-mitad {
      padding: 305px 0 100px;
  
      .hero-service_img {
        width: 50%;
      }
    }
  }

  .hero-discount {
    &_img {
      padding: 12px 100px 12px 55px;
    }
  
    &::before {
      right: 35%;
    }
  }
}
