.page-numbers {
  color: $blue;
  padding: 8px;
  transition: 0.5s ease;

  &:hover {
    text-decoration: underline;
    color: $dark-blue;
  }
}

.page-numbers.current {
 padding: 8px;
 color: $dark-blue;
}
