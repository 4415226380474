.listado-promociones_wrap {
  padding-top: $separacion-bloques-sm;
  padding-bottom: $separacion-bloques-sm;

  .service-sidebar .sidebar-cta {
    border-radius: 10px;
  }
}

.listado-promociones {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;

  .item-coupon {
    margin-bottom: 0;
    height: auto;
    width: 100%;

    &::before {
      background-color: $blanco;
    }
  }
}

@include breakpoint ('tablet') {
  .listado-promociones_wrap {
    padding-top: $separacion-bloques-md;
    padding-bottom: $separacion-bloques-md;
  }

  .listado-promociones {
    column-gap: 30px;
    justify-content: space-between;

    .item-coupon {
      width: 47%;
    }
  }
}

@include breakpoint ('desktop-lg') {
  .listado-promociones_wrap {
    padding-top: $separacion-bloques-lg;
    padding-bottom: $separacion-bloques-lg;

    .service-sidebar .sidebar-cta {
      padding: 30px;
    }
  }
}

@include breakpoint ('desktop-xl') {
  .listado-promociones_wrap {

    .service-sidebar {
      margin-left: 30px;
    }
  }
}
