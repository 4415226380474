.boton-principal {
  @include botonGeneral;
  @include botonPrincipal;
}

.boton-secundario {
  @include botonGeneral;
  @include botonSecundario;
}

.boton-outline-rojo {
  @include botonGeneral;
  @include botonOutline;
}

.boton-blanco {
  @include botonGeneral;

  border-color: $blanco;
  color: $red;
  background-color: $blanco;

  &:hover,
  &:focus {
    background-color: $red;
    color: $blanco;
    border-color: $blanco;
  }
}

.boton-azul {
  @include botonGeneral;
  
  border-color: $blue;
  color: $blanco;
  background-color: $blue;

  &:hover,
  &:focus {
    background-color: $blanco;
    color: $blue;
    border-color: $blue;
  }
}

.boton-secundario.boton-icono {
  padding: 18px 29px 18px 55px;
  position: relative;
  
  img {
    margin-right: 10px;
  }

  &:hover {
    &::before {
      background-image: url('img/icon-phone-red.svg');
    }
  }

  &::before {
    content: '';
    display: block;
    width: 21px;
    height: 21px;
    background-image: url('img/icon-phone.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    transition: 0.5s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
  }
}
