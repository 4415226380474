.bloque-discount {
  background-color: $blue;
  background-image: url('img/bg-trio.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100px 0;
  color: $blanco;
  padding: $separacion-bloques-sm 0;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    display: block;
    width: 70px;
    height: 100px;
    background-image: url('img/dot-grid.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.5;
  }

  &::before {
    content: '';
    display: block;
    width: 70px;
    height: 100px;
    background-image: url('img/dot-grid.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0;
    opacity: 0.5;
  }

  h2 {
    @include encabezadoH3;

    color: $blanco;
    margin-bottom: 15px;
  }

  &_info {
    margin-bottom: 30px;
    padding: 0 20px;
    text-align: center;
  }

  img {
    margin-bottom: 30px;
    width: 100%;
  }

  &_disclaimer {
    @include workSans_semibold;

    font-family: "Work Sans", Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba($blanco, 0.1);
      border-radius: 100px 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
}

@include breakpoint('tablet') {
  .bloque-discount {
    padding: $separacion-bloques-md 0;
  }
}

@include breakpoint('desktop-lg') {
  .bloque-discount {
    padding: $separacion-bloques-lg 0;

    &_info {
      margin-bottom: 0;
      padding: 0;
      text-align: left;
    }

    &_disclaimer {
      margin: 20px auto 0;
      max-width: 865px;
    }

    img {
      margin-bottom: 0;
    }

    h2 {
      @include encabezadoH2;
    }
  }
}

@include breakpoint('desktop-xl') {
  .bloque-discount {
    &_info {
      padding: 0 5px 0 0;
    }
  }
}
