.bloque-service-detail {
  padding: 60px 0 $separacion-bloques-sm;

  &_actions {
    .boton-outline-rojo {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.service-sidebar {
  box-shadow: 0px 0px 20px 0px #0000001F;
  border-radius: 10px;
  margin-top: 40px;

  .sidebar-cta {
    background-color: $red;
    color: $blanco;
    padding: 30px;  
    border-radius: 10px 10px 0 0;

    h4 {
      color: $blanco;
      margin-bottom: 30px;
      font-size: 28px;
      line-height: 33px;
    }
  }

  .boton-blanco {
    display: block;
  }

  .sidebar-certificates {
    padding: 30px;
    text-align: center;
    background-color: $fondo-1;

    h4 {
      font-size: 28px;
      line-height: 36px;
    }

    img {
      margin: 10px 20px;
    }
  }
}

.bloque-featured {
  padding: $separacion-bloques-sm 0;
  background-color: $fondo-2;

  &_encabezado {
    text-align: center;
    margin-bottom: 25px;
  }
}

.item-coupon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0D4985B2;
  border-radius: 10px;
  position: relative;
  color: $blanco;
  margin-bottom: 30px;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(0deg, rgba(13, 73, 133, 0.8), rgba(13, 73, 133, 0.7));
  }

  &::before {
    content: '';
    display: block;
    width: 51px;
    height: 51px;
    background-color: $fondo-2;
    position: absolute;
    right: -26px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    z-index: 2;
  }

  &_body {
    padding: 30px;
    position: relative;
    z-index: 2;
  }

  h3 {
    margin-bottom: 0;
  }

  h3,
  h4 {
    color: $blanco;
    transition: 0.5s;
  }

  &_img {
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;
  }

  img {
    max-width: 187px;
    width: 100%;
  }

  &_bottom {
    @include dmSans_bold;

    background-color: $red;
    display: flex;
    align-items: center;
    border-radius: 0 0 10px 10px;
    height: 51px;
    padding-left: 30px;
    font-size: 18px;
    line-height: 27px;
    position: relative;
    z-index: 2;
  }
}

a.item-coupon:hover {
  color: rgba($blanco, 0.6);

  h3,
  h4 {
    color: rgba($blanco, 0.6);
  }
}

.bloque-service-items {
  margin-bottom: 50px;

  h4 {
    margin-bottom: 32px;
  }

  &-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: center;
  }
}

.item-service {
  background-color: $blanco;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000001F;
  padding: 19px 19px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 152px;
  color: $dark-blue;

  &:hover {
    background-color: $red;
    color: $blanco;

    img {
      filter: invert(100%) sepia(100%) saturate(38%) hue-rotate(289deg) brightness(110%) contrast(110%);
    }
  }

  img {
    max-width: 50px;
    width: 100%;
    margin-bottom: 15px;
  }
}

@include breakpoint ('tablet') {
  .bloque-service-detail {
    padding: 60px 0 $separacion-bloques-md;

    &_actions {
      margin-bottom: 60px;

      .boton-outline-rojo {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 0;
      }
    }
  }

  .bloque-featured {
    padding: $separacion-bloques-md 0;
  }

  .item-coupon {
    display: flex;
    flex-wrap: wrap;
    min-height: 231px;
    align-items: center;
    height: calc(100% - 30px);
  
    &_body {
      width: 60%;

      p {
        margin-bottom: 0;
      }
    }

    &_body.body-variante {
      width: 100%;
    }
  
    &_img {
      width: 40%;
      margin-bottom: 0px;
      padding-right: 30px;
    }
  
    &_bottom {
      width: 100%;
      align-self: flex-end;
    }
  }

  .bloque-service-items {
    &-wrap {
      justify-content: flex-start;
    }
  }
}

@include breakpoint ('desktop-lg') {
  .bloque-service-detail {
    padding: 70px 0 $separacion-bloques-lg;

    &_actions {
      margin-bottom: 45px;

      .boton-outline-rojo {
        margin-bottom: 15px;
      }
    }
  }

  .service-sidebar {
    margin-top: 0;
  
    .sidebar-cta {
      padding: 60px;
    }
  
    .sidebar-certificates {
      padding: 60px;
      text-align: center;
    }
  }

  .bloque-featured {
    padding: $separacion-bloques-lg 0;
  }
}

@include breakpoint ('desktop-xl') {
  .bloque-service-detail {
    padding: 80px 0 $separacion-bloques-lg;
  }

  .contenido-wrap {
    padding-right: 40px;
  }
}
