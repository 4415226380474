/*
Theme Name: Pacific Air
Author: Nett
Author URI: http://nett.mx/
Description: Custom theme for Pacific Air
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/

@import "_config.scss";
@import "base/_base.scss";
@import "base/_boilerplate.scss";
@import "base/_form.scss";
@import "components/_botones.scss";
@import "components/_footer.scss";
@import "components/_header.scss";
@import "components/_menu-mobile.scss";
@import "components/addons/_light-gallery.scss";
@import "components/blocks/_bloque-acordeon.scss";
@import "components/blocks/_bloque-columnas.scss";
@import "components/blocks/_bloque-community.scss";
@import "components/blocks/_bloque-cta-logo.scss";
@import "components/blocks/_bloque-cta.scss";
@import "components/blocks/_bloque-discount.scss";
@import "components/blocks/_bloque-numeralia.scss";
@import "components/blocks/_bloque-slide-team.scss";
@import "components/blocks/_bloque-testimoniales.scss";
@import "components/blocks/_bloque-tips.scss";
@import "components/blocks/_contenido.scss";
@import "components/blocks/_gravity-forms.scss";
@import "components/blocks/_hero.scss";
@import "components/blocks/_pagination.scss";
@import "components/blocks/_slider.scss";
@import "components/views/_404.scss";
@import "components/views/_about.scss";
@import "components/views/_careers.scss";
@import "components/views/_contact.scss";
@import "components/views/_detail.scss";
@import "components/views/_gallery.scss";
@import "components/views/_home.scss";
@import "components/views/_membership.scss";
@import "components/views/_promotions.scss";
@import "components/views/_service-detail.scss";
