body {
  @include dmSans_regular;

  color: $dark-blue-2;
  font-size: 17px;
  line-height: 24px;
}

menu, ol, ul {
  padding: 0 0 0 20px;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

button {
  background: transparent;
  border: none;
}

a,
button {
  transition: 0.5s;
}

a:focus,
a:focus,
button:focus,
input:focus {
  outline: none;
}

// Encabezados
h1,
h2,
h3,
h4,
h5,
h6 {
  @include workSans_bold;

  color: $dark-blue;
}

img {
  height: auto;
  max-width: 100%;
}

h1 {
  @include encabezadoH1;
}

h2 {
  
  @include encabezadoH2;
}

h3 {
  @include encabezadoH3;
}

h4 {
  @include encabezadoH4;
}

h5 {
  @include encabezadoH5;
}

h6 {
  @include encabezadoH6;
}
