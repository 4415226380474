.bloque-cta-logo {
  background-color: $fondo-1;
  padding: $separacion-bloques-sm 0;

  h2 {
    margin-bottom: 18px;
  }

  &_info {
    margin-bottom: 30px;
  }

  &-description {
    font-size: 22px;
    line-height: 31px;
  }

  img {
    max-width: 525px;
    width: 100%;
  }
}

@include breakpoint('tablet') {
  .bloque-cta-logo {
    padding: $separacion-bloques-md 0;
  }
}

@include breakpoint('desktop-lg') {
  .bloque-cta-logo {
    padding: $separacion-bloques-lg 0;
  }
}
