$menu_mobile_item_alto: 60px;
$menu_mobile_item_borde_color: #EBEBEB;

// Menú
.mm-menu {
  .mm-panel,
  .mm-panels {
    background-color: $fondo-1;
    color: $negro;
  }

  .mm-panel {
    padding-left: 0;
    padding-right: 0;
  }

  .mm-panel:after,
  .mm-panel:before {
    content: none;
  }

  // Navbar
  .mm-navbar {
    background-color: rgba($blanco, 0.1);
    border-color: $menu_mobile_item_borde_color;
    color: $negro;
    display: block;
    height: $menu_mobile_item_alto;
  }

  .mm-panel:first-child {
    .mm-navbar {
      display: none;
    }
  }

  // Listview
  .mm-panels > .mm-panel > .mm-listview {
    margin-left: 0;
    margin-right: 0;
  }

  .mm-panels > .mm-panel > .mm-navbar + .mm-listview {
    margin-top: 0;
  }

  .mm-panels>.mm-panel>.mm-listview {
    margin: 0;
  }

  .mm-panels > .mm-panel:not(:first-child) > .mm-navbar + .mm-listview {
    margin-top: $menu_mobile_item_alto;
  }

  .mm-listitem {

    &.menu-item__request {
      padding: 18px 20px;

      a {
        @include botonPrincipal;
        @include botonGeneral;

        text-align: center;
        border: 2px solid $red;
      }
    }
  }

  .mm-listitem:after {
    left: 0;
    background-color: rgba($dark-blue, 0.1);
  }
  
  // Botones
  .mm-listitem__btn,
  .mm-listitem__text,
  .mm-navbar__title {
    padding-bottom: 18px;
    padding-top: 18px;
    padding: 18px 15px;
  }

  .mm-listitem__text,
  .mm-navbar__title {
    color: $negro;
    font-size: 18px;
    line-height: 22px;
  }

  .menu-principal__items .mm-listitem__text {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mm-navbar__title {
    padding-left: 5px;
    padding-right: 5px;
  }

  .mm-listitem__btn:not(.mm-listitem__text) {
    border-left-color: $menu_mobile_item_borde_color;
  }

  // Activo
  .mm-listitem_selected,
  .active {
    .mm-listitem__text {
      @include workSans_semibold;
      
      color: $color-principal;
    }
  }

  .active.menu-item__request {
    .mm-listitem__text {
      background-color: rgba($blanco, 0);
      color: $red;
    }
  }
}

.menu-item-mobile {
  display: none;
}

// Hamburguesa
.mburger {
  @include botonHamburguesa($blanco, 38px);
  // @include botonHamburguesa($dark-blue-2, 38px);

  &:hover {
    color: rgba($blanco, 0.5);
    // color: rgba($negro, 0.5);
  }
}

.menu-item_locations {
  .dropdown-item.mm-listitem__text {
    text-overflow: none;
    white-space: unset;
  }
}

.menu-card_wrap {
  display: none;
}

@media (min-width: $break-lg-min) {
  .no-touch #button-open-menu-mobile {
    display: none;
  }

  .menu-item-mobile {
    display: inline-block;
  }
}
