.bloque-tips {
  padding: $separacion-bloques-sm 0;

  &-descripcion {
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 28px;
  }
}

.item-tips {
  margin-bottom: 30px;
  color: $dark-blue-2;
  display: block;

  &:hover {
    color: $dark-blue-2;

    h3 {
      color: $blue;
    }

    .item-tips_img::before {
      background-color: #003978B2;
    }
  }

  &_img {
    border-radius: 10px;
    padding: 50px 30px;
    min-height: 255px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $fondo-1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blanco;
    text-align: center;
    margin-bottom: 24px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;
      transition: 0.5s;
      background-color: #0000004D;
    }
  }

  &_title {
    @include workSans_bold;

    display: block;
    font-size: 22px;
    line-height: 33px;
    position: relative;
  }

  &_date {
    display: block;
    padding: 10px;
    background-color: #00000033;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    line-height: 20px;
  }
}

.items-listado-wrapper {
  padding: $separacion-bloques-sm 0;
  border-bottom: 1px solid #DDDDDD;
}

@include breakpoint('tablet') {
  .bloque-tips {
    padding: $separacion-bloques-md 0;
  }

  .item-tips.item-tips-lg {
    display: flex;
    flex-direction: row;
    margin-bottom: 45px;

    .item-tips_des {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    .item-tips_img {
      width: 40%;
      margin-bottom: 0;
      margin-right: 30px;
      flex-grow: 1;
    }

    .item-tips_title {
      max-width: 350px;
    }
  }

  .items-listado-wrapper {
    padding: $separacion-bloques-md 0;
  }
}

@include breakpoint('desktop-lg') {
  .bloque-tips {
    padding: $separacion-bloques-lg 0;
  }

  .items-listado-wrapper {
    padding: $separacion-bloques-lg 0;
  }

  .item-tips.item-tips-lg {
    margin-bottom: 60px;
  }
}

