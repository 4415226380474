.contenido-administrable {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px;
    margin-top: 26px;
  }

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  blockquote {
    background-color: #0039780D;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 19px;
    padding: 32px;
    border-left: 5px solid $blue;
  }

  a {
    &:visited {
      color: $negro;
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    margin-top: 10px;
  }

  ul {
    list-style: none;
    margin-bottom: 30px;
    padding-left: 0;
  
    li {
      font-size: 18px;
      line-height: 27px;
      padding-left: 21px;
      position: relative;
  
      &::before {
        content: '';
        height: 8px;
        left: 0;
        position: absolute;
        top: 9px;
        width: 8px;
        background-color: $red;
        border-radius: 50%;
      }
  
      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
}

.lista-checks {
  list-style: none;
  margin-bottom: 30px;
  padding-left: 0;

  li {
    font-size: 18px;
    line-height: 27px;
    padding-left: 38px;
    position: relative;

    &::before {
      @include backgroundGeneral;

      background-image: url('img/icon-check-circle.svg');
      background-size: 22px;
      content: '';
      height: 24px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.lista-flechas {
  list-style: none;
  margin-bottom: 30px;
  padding-left: 0;

  li {
    font-size: 18px;
    line-height: 27px;
    padding-left: 38px;
    position: relative;

    &::before {
      @include backgroundGeneral;

      background-image: url('img/arrow-white-rigth.svg');
      background-size: 22px;
      content: '';
      height: 24px;
      left: 0;
      position: absolute;
      width: 24px;
      top: 1px;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.borde-inferior {
  position: relative;

  &::after {
    content: '';
    display: block;
    background: #DDDDDD;
    width: calc(100% - 30px);
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
  }
}

.contenido-wrapper-general {
  padding-bottom: $separacion-bloques-lg;
  padding-top: 198px;
  // border-bottom: 1px solid #DDDDDD;
}

.encabezado-small {
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 30px;
}

@include breakpoint('tablet-sm') {
  .borde-inferior {
    &::after {
      max-width: 510px;
    }
  }
}

//Tablet
@media (min-width: $break-sm-min) {
  .contenido-administrable {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 25px;
      margin-top: 34px;
    }
  }

  .borde-inferior {
    &::after {
      max-width: 690px;
    }
  }
}

//Desktop LG
@media (min-width: $break-lg-min) {
  .contenido-wrapper {
    padding-bottom: $separacion-bloques-lg;
    padding-top: $separacion-bloques-lg;

    .bloque-general {
      padding-bottom: $separacion-bloques-lg / 2;
      padding-top: $separacion-bloques-lg / 2;
    }
  }

  .contenido-administrable {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 30px;
      margin-top: 39px;
    }
  }

  .borde-inferior {
    &::after {
      max-width: 930px;
    }
  }

  .contenido-wrapper-general {
    padding-top: 228px;
  }
}

@include breakpoint('desktop-xl') {
  .borde-inferior {
    &::after {
      max-width: 1110px;
    }
  }
}

@include breakpoint('desktop-xxl') {
  .borde-inferior {
    &::after {
      max-width: 1320px;
    }
  }
}
