// Mensajes de error y éxito
.wpcf7-not-valid { border: 1px solid $input_borde_error; }

.wpcf7-response-output {
  background-color: $mensaje_correcto_background;
  border-color: $mensaje_correcto_color;
  color: $mensaje_correcto_color;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 19px 15px 17px 15px;
  text-align: center;
}

.wpcf7-validation-errors {
  background-color: transparent;
  border-color: $input_borde_error;
  color: $input_borde_error;
}

.wpcf7-not-valid-tip {
  color: #ff0000;
  font-size: 12px;
  line-height: 1.2;
  margin-top: -14px;
}

label {
  line-height: 30px;
  margin-bottom: 3px;
}

input,
textarea,
select,
.form-control {
  background-color: $blanco;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  color: #666666;
  min-height: 50px;
  width: 100%;

  &:focus {
    border-color: $blue;
    border-width: 1px;
    box-shadow: none;
    color: #666666;
  }
}

input[type=file]::file-selector-button {
  display: inline-block;
  font-size: 198x;
  line-height: 25px;
  min-width: 100px;
  min-height: 48px;
  padding: 12px 14px;
  transition: 0.5s;
  text-align: center;
  border-radius: 3px 0  0 3px;
  border: 1px solid #DDDDDD;
  color: #465362;
}

.form-control {
  margin-bottom: 15px;
  position: relative;
}

form.formulario-contacto {
  button[type='submit'],
  input[type='submit'],
  input[type='button'] {
    @include botonGeneral;

    background-color: $red;
    border-color: $red;
    color: $blanco;
    display: block;  
    margin-top: 25px;
    width: 100%;

    &:hover,
    &:focus {
      background-color: $blanco;
      color: $red;
    }
  }

  &.wpcf7-form.submitting .ajax-loader {
    display: block;
  }

  &.wpcf7-form .ajax-loader {
    background-color: rgba($blanco, 0);
    background-image: url(img/loader.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    height: 25px;
    margin: 30px auto 0;
    width: 25px;

    &::before {
      content: none;
    }
  }
  
  .wpcf7-response-output {
    @include workSans_regular;
    
    background-color: $blanco;
    border-radius: 3px;
    color: #ff0000;
    font-size: 16px;
    padding: 17px 20px 16px;
  }

  &.sent .wpcf7-response-output {
    color: #46b450;
  }

  .wpcf7-not-valid {
    border-color: #ff0000;

    &:focus {
      border-color: $blue;
    }
  }
}

.wpcf7 {
  .form-contacto .wpcf7-response-output,
  .form-contacto.spam .wpcf7-response-output,
  .form-contacto.aborted .wpcf7-response-output,
  .form-contacto.failed .wpcf7-response-output,
  .form-contacto.invalid .wpcf7-response-output,
  .form-contacto.unaccepted .wpcf7-response-output {
    border-color: #ff0000;
    margin: 30px 0 0;
  }
}
