.bloque-columnas {
  padding: $separacion-bloques-sm 0;

  .logo-encabezado {
    margin-bottom: 30px;
    text-align: center;

    img {
      max-width: 350px;
    }
  }

  .logo-encabezado_descr div {
    @include workSans_bold;

    display: inline-block;
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 16px;
    padding-right:  60px;
    position: relative;
    width: fit-content;

    &::after {
      content: '';
      display: block;
      width: 45px;
      height: 45px;
      background-size: 45px;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }

  .good-people::after {
    background-image: url('img/positive-vote.png');
  }

  .happy-customers::after {
    background-image: url('img/person.png');
  }

  .plant-a-tree::after {
    background-image: url('img/tree.png');
  }
}

.item-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  text-align: center;

  &_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px #21842124;
    margin-bottom: 57px;
    max-height: 255px;
    position: relative;

    img {
      bottom: -45px;
      max-width: 115px;
      position: absolute;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &_img::before {
    content: '';
    display: block;
    padding-top: 70%;
  }

  h3 {
    @include workSans_semibold;

    margin-bottom: 19px;
  }

  .item-column-description {
    margin-bottom: 28px;
  }

  .boton-secundario {
    margin-top: auto;
  }
}

@include breakpoint ('tablet') {
  .bloque-columnas {
    padding: $separacion-bloques-md 0;
  
    .logo-encabezado {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: left
    }

    .logo-encabezado_descr {
      display: flex;
      flex-direction: column;
    }
  }

  .item-column {
    height: calc(100% - 35px);
    justify-content: space-between;

    .boton-principal {
      display: inline-block;
    }
  }
}
