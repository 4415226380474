.bloque-video {
  padding: $separacion-bloques-sm 0;
  text-align: center;

  h3 {
    margin-bottom: 16px;
  }

  .bloque-descripcion {
    margin-bottom: 40px;
  }

  .contenido-administrable {
    border-radius: 10px;
    overflow: hidden;
  }
}

.bloque-logo {
  background-color: $fondo-1;
  padding: $separacion-bloques-sm 0;

  h3:not(:last-child) {
    margin-bottom: 30px;
  }

  &_img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 486px;
    width: 100%;
  }
}

@include breakpoint ('tablet') {
  .bloque-video {
    padding: $separacion-bloques-md 0;
  }  

  .bloque-logo {
    padding: $separacion-bloques-md 0;
  }
}

@include breakpoint ('desktop-lg') {
  .bloque-video {
    padding: $separacion-bloques-lg 0;
  }  

  .bloque-logo {
    padding: $separacion-bloques-lg - 35px 0;
  }
}
