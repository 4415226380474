.bloque-acordeon_wrapper {
  padding: $separacion-bloques-sm 0;

  h3 {
    margin-bottom: 30px;
  }
}

.bloque-acordeon {
  background-color: $blanco;
  box-shadow: 0px 0px 20px 0px #0000001F;
  border-radius: 10px;
  margin-bottom: 30px;

  &__item {
    overflow: hidden;
    
    &:not(:last-child) {
      border-bottom: 1px solid #DDDDDD;
    }

    &:first-child  {
      border-radius: 10px 10px 0 0;
    }

    &:last-child  {
      border-radius: 0 0 10px 10px;
    }
  }

  &__link {
    @include workSans_semibold;

    font-size: 18px;
    line-height: 27px;
    padding: 22px 55px 22px 20px;
    transition: 0.5s;
    display: block;
    background-color: $dark-blue;
    color: $blanco;
    position: relative;

    &:hover {
      background-color: rgba($blue, 0.5);
      color: $dark-blue;
    }

    &.collapsed {
      background-color: $blanco;
      color: $dark-blue;

      &:hover {
        background-color: rgba($blue, 0.5);
        color: $dark-blue;
      }
    }

    &::after {
      background-image: url('img/icon-menos.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 22px;
      content: '';
      display: block;
      height: 30px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
    }

    &.collapsed::after {
      background-image: url('img/icon-mas.svg');
      background-size: 22px;
    }
  }

  &__colapsable-inner {
    @include dmSans_regular;

    color: $dark-blue-2;
    padding: 20px;
  }
}

.slide-video {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;

  iframe {
    border-radius: 10px;
    width: 100%;
  }
}

.slide-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: 60%;
  }
}

@include breakpoint ('tablet') {
  .bloque-acordeon_wrapper {
    padding: $separacion-bloques-md 0;
  }
}

@include breakpoint ('desktop-lg') {
  .bloque-acordeon_wrapper {
    padding: $separacion-bloques-lg 0;
  }

  .bloque-acordeon {
    margin-bottom: 0;
  }
}
