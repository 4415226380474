.bloque-numeralia {
  background-color: $blue;
  padding: $separacion-bloques-sm 0;
  color: $blanco;
  position: relative;
  z-index: 1;
  background-image: url('img/bg-numbers.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0D4985CC;
    z-index: -1;
  }

  h3 {
    color: $blanco;
    text-align: center;
  }

  &_des {
    margin-bottom: 25px;
    text-align: center;
  }
}

.item-numeralia {
  margin-bottom: 30px;

  h4 {
    color: $blanco;
    font-size: 45px;
    line-height: 67px;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }

  img {
    max-width: 35px;
    margin-right: 11px;
  }
}

.bloque-detalles {
  background-color: $blanco;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #0000001F;
  margin-bottom: -100px;
  margin-top: 30px;

  .item-detalle {
    padding: 32px;
    color: $dark-blue-2;

    &:not(:last-child) {
      border-bottom: 1px solid #DDDDDD;
    }

    &_img {
      background-color: #0039781A;
      border-radius: 10px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 58px;
      height: 58px;
      margin-bottom: 32px;
    }

    h4 {
      font-size: 24px;
      line-height: 36px;
    }

    a {
      text-decoration: underline;
      color: $red;

      &:hover {
        color: rgba($red, 0.5);
      }
    }
  }
}

.mapa-responsive {
  background-color: $fondo-1;
  overflow: hidden;
  position: relative;
  z-index: 0;
  max-height: 500px;

  &::before {
    content: '';
    display: block;
    padding-bottom: 80%;
  }

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

@include breakpoint('tablet') {
  .bloque-numeralia {
    padding: $separacion-bloques-md 0;

    &_des {
      margin-bottom: 35px;
    }
  }

  .bloque-detalles {
    margin-bottom: -150px;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
  
    .item-detalle {
      width: 50%;

      &:nth-child(odd) {
        border-right: 1px solid #DDDDDD;
      }

      &:nth-child(3) {
        border-bottom: 0;
      }
    }
  }
}

@include breakpoint('desktop-lg') {
  .bloque-numeralia {
    padding: $separacion-bloques-lg 0;
  }

  .item-numeralia {
    margin-bottom: 0;
  }

  .bloque-detalles {
    margin-bottom: -200px;
    margin-top: 60px;
  
    .item-detalle {
      width: 25%;
  
      &:not(:last-child) {
        border-bottom: 0;
        border-right: 1px solid #DDDDDD
      }
    }
  }
}

@include breakpoint('desktop-xl') {
  .item-numeralia {
    padding: 0 30px;
  }
}
