.gallery-wrapper {
  padding: 40px 0;
  border-bottom: 1px solid #DDDDDD;
}

.gallery-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
}

.item-grid {
  height: 265px;
  width: 100%;
}

.item-grid_img {
  background-color: $dark-blue;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.bloque--contenedor-anclas {
  align-items: center;
  display: flex;
  margin-bottom: 30px;
}

.bloque--link-anclas {
  margin-bottom: 30px;
  .gallery-tab {
    @include workSans_regular;

    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    color: $dark-blue-2;
    padding: 18px 32px;
    border-radius: 10px;

    &:hover {
      background-color: #d123290c;
    }
  }

  .gallery-tab.active {
    @include workSans_bold;

    background-color: #D123291A;
    color: $red;
  }
}

@include breakpoint ('tablet') {
  .gallery-wrapper {
    padding: 50px 0;

    .tab-content {
      width: 100%;
    }
  }

  .gallery-wrap {
    justify-content: start;
  }

  .bloque--link-anclas {
    padding-right: 50px;

    .gallery-tab {
      text-align: left;
    }
  }

  .gallery-wrap {
    gap: 24px;
  }

  .item-grid {
    width: 30%;
  
    &::before {
      padding-top: 60%;
    }
  }
}

@include breakpoint ('desktop-lg') {
  .gallery-wrapper {
    padding: 60px 0;
  }

  .gallery-wrap {
    gap: 27px;
  }

  .item-grid {
    width: 22%;
  
    &::before {
      padding-top: 100%;
    }
  }

  .bloque--link-anclas {
    padding-right: 70px;
    min-width: 270px;
  }
}

