// 1. Colores
$negro: #000000;
$blanco: #FFFFFF;
$red: #D12329;
$blue: #003978;
$dark-blue: #2D3047;
$dark-blue-2: #465362;
$fondo-1: #F9F9F9;
$fondo-2: #E6EBF1;
$fondo-3: #F2F2F2;

$color-principal: $red;

// 2. Breakpoints
$break-xs-max: 767px;
$break-sm-min: 480px;
$break-md-min: 768px;
$break-md-max: 991px;
$break-lg-min: 992px;
$break-lg-max: 1199px;
$break-xl-min: 1200px;
$break-xl-max: 1399px;
$break-xxl-min: 1400px;

$breakpoints: (
  'mobile-max'    : $break-xs-max,
  'tablet-sm'     : $break-sm-min,
  'tablet'        : $break-md-min,
  'tablet-max'    : $break-md-max,
  'desktop-lg'    : $break-lg-min,
  'desktop-lg-max': $break-lg-max,
  'desktop-xl'    : $break-xl-min,
  'desktop-xl-max': $break-xl-max,
  'desktop-xxl'   : $break-xxl-min
);
// Mixin breakpoint -> @include breakpoint('tablet') { } || @include breakpoint($value) { }
@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else if unit($breakpoint) == 'px' {
    @media (min-width: $breakpoint) {
      @content;
    }
  }

  @else {
    @error "No existe un valor: `#{$breakpoint}`, en la lista de breakpoints."
    + "Los valores disponibles son: #{map-keys($breakpoints)}.";
  }
}

// 3. Variables generales
//  - Separaciones
$separacion-bloques-sm: 40px;
$separacion-bloques-md: 50px;
$separacion-bloques-lg: 60px;

// - Formulario
$input-borde-error: #d00000;
$input-borde-color: #dedede;
$input-focus-color: $negro;
$input-radius: 4px;
$inputs-alto: 52px;

$mensaje_correcto_background: #FBFAFB;
$mensaje_correcto_color: #498F63;
$mensaje_error_background: rgba(255, 0, 0, 0.2);

// Checkbox / Radio botones
$checkbox-radio-size: 17px;
$radio-dot-gap: 6px;

// - Slick
$width-flechas-slick:  38px;
$height-flechas-slick: 38px;

$color-de-borde: #4C4C4C;
$color-de-fondo-activo: #545454;
$color-de-fondo: #000000;
$puntos-slider-separacion-desktop: 20px;
$puntos-slider-separacion-mobile: 20px;
$slider-punto-ancho: 10px;

// 4. Tipografía
$tipografia-principal: 'Work Sans', Helvetica, Arial, sans-serif;
$tipografia-secundaria: 'DM Sans', Helvetica, Arial, sans-serif;

@mixin workSans_regular {
  font-family: $tipografia-principal;
  font-style: normal;
  font-weight: 400;
}

@mixin workSans_semibold {
  font-family: $tipografia-principal;
  font-weight: 600;
}

@mixin workSans_bold {
  font-family: $tipografia-principal;
  font-weight: 700;
}

@mixin workSans_extrabold {
  font-family: $tipografia-principal;
  font-weight: 800;
}

@mixin dmSans_regular {
  font-family: $tipografia-secundaria;
  font-style: normal;
  font-weight: 400;
}

@mixin dmSans_bold {
  font-family: $tipografia-secundaria;
  font-weight: 700;
}

// 5. Mixins generales
@mixin clearFloat {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

@mixin cuadradoResponsive {
  &::before {
    content: '';
    display: table;
    float: left;
    padding-top: 100%;
  }
}

@mixin gradienteTopBottom($color_top, $color_bottom, $color_fallback) {
  background: $color_fallback;
  background: -moz-linear-gradient($color_top, $color_bottom);
  background: -o-linear-gradient($color_top, $color_bottom);
  background: -webkit-linear-gradient($color_top, $color_bottom);
  background: linear-gradient($color_top, $color_bottom);
}

@mixin resetearCSSnavbar {
  border-radius: 0;
  border: none;
  margin-bottom: 0;
}

@mixin resetearSelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &::-ms-expand {
    display: none;
  }
}

@mixin labelStyles {
  display: block;
  font-size: 13px;
  letter-spacing: 1.5px;
  line-height: 20px;
  margin-bottom: 12px;
  text-transform: uppercase;
}

@mixin fieldStyles {
  border: 1px solid $input-borde-color;
  border-radius: $input-radius;

  &:focus {
    border-color: $color-principal;
    box-shadow: none;
    outline: none;
  }
}

@mixin selectStyles($icono) {
  @include resetearSelect;
  @include backgroundGeneral;
  @include backgroundImg(#{$icono});

  background-color: $blanco;
  background-position: calc(100% - 26px) center;
  background-size: 10px 6px;
  height: $inputs-alto;
}

@mixin checkboxRadioStyles {
  input[type='radio'],
  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    z-index: -1;

    + label {
      color: #a7a7a7;
      cursor: pointer;
      display: flex;
      font-size: 10px;
      letter-spacing: 1px;
      position: relative;
    }

    + label::before {
      border: 1px solid $input-borde-color;
      content: '';
      display: block;
      flex-shrink: 0;
      height: $checkbox-radio-size;
      margin-right: 10px;
      width: $checkbox-radio-size;
    }

    + label a {
      color: $negro;
      text-decoration: underline;
    }

    + label a:hover {
      color: $color-principal;
    }
  }
}

@mixin checkboxStyle($icono) {
  input[type='checkbox'] {
    &:checked + label::before {
      @include backgroundImg(#{$icono});

      border-color: $color-principal;
    }

    + label::before {
      background-size: 11px 9px;
      border-radius: $input-radius;
    }
  }
}

@mixin inputRadioStyles {
  input[type='radio'] {
    &:checked + label::after {
      background-color: $color-principal;
      border-radius: 50%;
      content: '';
      display: block;
      height: ($checkbox-radio-size - $radio-dot-gap);
      left: ($radio-dot-gap / 2);
      position: absolute;
      top: ($radio-dot-gap / 2);
      width: ($checkbox-radio-size - $radio-dot-gap);
    }

    &:checked + label::before {
      border-color: $color-principal;
    }

    + label::before {
      border-radius: 50%;
    }
  }
}

@mixin ellipsisSingleLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin backgroundOverlay {
  &:before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

@mixin backgroundGeneral {
  background-position: center;
  background-repeat: no-repeat;
}

@mixin backgroundImg($img) {
  background-image: url('../img/#{$img}');
}

@mixin estilosMenuMobile {
  $mm-color-border: rgba($negro, 0.2);
  $mm-color-button: rgba($negro, 0.3);
  $mm-color-background-highlight: rgba($negro, 0.05);

  --mm-line-height: 20px;
  --mm-listitem-size: 44px;
  --mm-navbar-size: 44px;
  --mm-offset-top: 0;
  --mm-offset-right: 0;
  --mm-offset-bottom: 0;
  --mm-offset-left: 0;
  --mm-color-border: #{$mm-color-border};
  --mm-color-button: #{$mm-color-button};;
  --mm-color-text: $gris;
  --mm-color-text-dimmed: #{$mm-color-button};
  --mm-color-background: #131B22;
  --mm-color-background-highlight: #{$mm-color-background-highlight};
  --mm-color-background-emphasis: rgba(255, 255, 255, 0);
}

// Para mmenu.js
@mixin botonHamburguesa($color, $size) {
  --mb-animate-timeout: 0;
  --mb-bar-height: 2px;
  --mb-bar-spacing: 8px;
  --mb-bar-width: 1;
  --mb-button-size: #{$size};

  color: $color;
  display: block;
  margin-left: auto;
}

// 6. Botones
@mixin botonGeneral {
  @include workSans_bold;

  display: inline-block;
  font-size: 19px;
  line-height: 22px;
  min-width: 175px;
  min-height: 62px;
  padding: 19px 29px;
  transition: 0.5s;
  text-align: center;
  border-radius: 7px;
  border: 2px solid;
}

@mixin botonPrincipal {
  border-color: $color-principal;
  color: $blanco;
  background-color: $color-principal;

  &:hover,
  &:focus {
    background-color: $blanco;
    color: $color-principal;
    border-color: $color-principal;
  }
}

@mixin botonSecundario {
  border-color: $blanco;
  color: $blanco;
  background-color: rgba($blanco, 0);

  &:hover,
  &:focus {
    background-color: $blanco;
    color: $color-principal;
  }
}

@mixin botonOutline {
  border-color: $color-principal;
  color: $color-principal;
  background-color: rgba($blanco, 0);

  &:hover,
  &:focus {
    background-color: rgba($color-principal, 0.2);
    color: $color-principal;
  }
}

// 7. Encabezados
@mixin encabezadoH1 {
  font-size: 39px;
    line-height: 45px;

  @include breakpoint('tablet') {
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 1px;
  }
}

@mixin encabezadoH2 {
  font-size: 38px;
  line-height: 49px;
}

@mixin encabezadoH3 {
  font-size: 32px;
  line-height: 38px;
}

@mixin encabezadoH4 {
  font-size: 22px;
  line-height: 33px;
}

@mixin encabezadoH5 {
  font-size: 21px;
  line-height: 31px;
}

@mixin encabezadoH6 {
  font-size: 18px;
  line-height: 27px;
}

// 8. Slick
@mixin slickArrows {
  height: $height-flechas-slick;
  width: $width-flechas-slick;
  z-index: 2;

  &::before {
    content:'';
  }
}

@mixin slickArrowPrev($icono-prev-slick) {
  @include backgroundGeneral;

  background-image: url('img/#{$icono-prev-slick}');
}

@mixin slickArrowNext($icono-next-slick) {
  @include backgroundGeneral;

  background-image: url('img/#{$icono-next-slick}');
}

@mixin puntosSlider($color-fondo) {
  align-items: center;
  bottom: auto;
  display: flex;
  justify-content: center;
  left: 0;
  line-height: 0;
  list-style: none;
  margin-bottom: 0;
  margin-top: 40px;
  padding: 0 15px;
  position: static;
  right: 0;

  li,
  li button {
    height: $slider-punto-ancho;
    width: $slider-punto-ancho;
  }

  li {
    line-height: 0;
    margin: 0 $puntos-slider-separacion-mobile/2;
  }

  li button {
    background-color: $color-fondo;
    border: 1px solid $color-fondo;
    border-radius: 50%;
    box-shadow: none;
    overflow: hidden;
    padding: 0;
    text-indent: -9999px;

    &::before {
      content: none;
    }
  }

  li.slick-active button {
    background-color: rgba($color-fondo, 0);
  }

  // Tablet
  @media (min-width: $break-md-min) {
    margin-top: 50px;

    li,
    li button {
      height: $slider-punto-ancho;
      width: $slider-punto-ancho;
    }

    li {
      margin-left: $puntos-slider-separacion-desktop/2;
      margin-right: $puntos-slider-separacion-desktop/2;
    }
  }
}
