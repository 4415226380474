.slider-general {
  .slick-dots li.slick-active button:before {
    background-color: $red;
  }

  .slick-dots li button:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #d12329d3;
  }

  .slick-dots {
    bottom: -35px;
  }
}

.slider-flechas {
  .slick-arrow {
    @include slickArrows;

    &:hover {
      opacity: 0.5;
    }
  }

  .slick-prev {
    @include slickArrowPrev('arrow-red-left.svg');

    left: auto;
    right: 53px;
    top: -15px;
  }

  .slick-next {
    @include slickArrowNext('arrow-red-rigth.svg');

    right: 15px;
    top: -15px;
  }
}

@include breakpoint('tablet'){
  .slider-flechas {
  
    .slick-prev {
      right: 72px;
      top: -45px;
    }
  
    .slick-next {
      right: 35px;
      top: -45px;
    }
  }
}

@include breakpoint('desktop-lg'){
  .slider-flechas {
  
    .slick-prev {
      right: 65px;
    }
  
    .slick-next {
      right: 29px;
    }
  }
}

@include breakpoint('desktop-xl'){
  .slider-flechas {
  
    .slick-prev {
      right: 80px;
    }
  
    .slick-next {
      right: 45px;
    }
  }
}

@include breakpoint('desktop-xxl'){
  .slider-flechas {
  
    .slick-prev {
      right: 205px;
    }
  
    .slick-next {
      right: 169px;
    }
  }
}
