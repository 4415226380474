.footer {
  background-color: $blanco;
  padding: 40px 0;
  font-size: 18px;
  line-height: 27px;
  border-top: 1px solid #DDDDDD;

  a {
    color: $color-principal;
    text-decoration: underline;
    display: block;

    &:hover,
    &:visited,
    &:focus {
      color: rgba($red, 0.5);
    }
  }
}

.footer-logo-wrap {
  margin-bottom: 25px;

  img {
    max-width: 127px;
    width: 100%;
    margin-bottom: 10px;
  }

  .footer-description {
    margin-bottom: 15px;
  }

  span {
    @include workSans_semibold;

    display: block;
    margin-bottom: 15px;
    color: $dark-blue;
  }

  .footer-legals {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      font-size: 17px;
      line-height: 20px;
    }
  }
}

.footer-address {
  .address-item {
    margin-bottom: 22px;

    h6 {
      @include workSans_semibold;

      margin-bottom: 5px;
    }
  }
}

.footer-hours {
  margin-bottom: 20px;

  h6 {
    @include encabezadoH4;
  }

  span {
    display: block;
  }
}

.footer-social {
  span {
    @include workSans_semibold;

    display: block;
    margin-bottom: 17px;
  }

  .footer-social-links {
    display: flex;
    align-items: flex-end;

    a {
      padding-right: 8px;
      margin-right: 14px;
    }

    a:hover {
      opacity: 0.7;
    }
  }
}

@include breakpoint('tablet-sm') {
  .footer-address {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .address-item {
      width: 49%;
    }
  }

  .footer-logo-wrap {
    .footer-legals {
      display: flex;

      li {
        position: relative;
      }

      li:first-child {
        padding-right: 5px;
      }

      li:last-child {
        padding-left: 5px;
      }

      li:first-child::after {
        content: '';
        display: block;
        width: 1px;
        height: 20px;
        background-color: $color-principal;
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 15px;
      }
    }
  }
}

@include breakpoint('tablet') {
  .footer-address {
    justify-content: start;
    .address-item {
      span {
        width: 220px;
        display: block;
      }
    }
  }
}

@include breakpoint('desktop-lg') {
  .footer {
    padding: 60px 0;
  }
  
  .footer-logo-wrap {
    margin-bottom: 20px;
    max-width: 332px;
  
    img {
      margin-bottom: 22px;
    }
  
    .footer-description {
      margin-bottom: 24px;
    }
  
    span {
      margin-bottom: 20px;
    }
  
    .footer-legals {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .footer-address {
    justify-content: space-between;

    .address-item {
      margin-bottom: 31px;
      width: 47%;
  
      h6 {
        margin-bottom: 9px;
      }
    }
  }
  
  .footer-hours {
    margin-bottom: 31px;
  
    span {
      display: block;
      max-width: 220px;
    }
  }
}

@include breakpoint('desktop-xl') {
  .footer-address {
    .address-item {
      width: 49%;
    }
  }
}
